import Session from 'supertokens-web-js/recipe/session';
import SuperTokens from 'supertokens-web-js';
import EmailPassword from "supertokens-web-js/recipe/emailpassword";
import { SuperTokensController as STC } from './SuperTokensController.js';

STC.init();
async function getUser() {
    let bOpus_user = localStorage.getItem("bOpus_user");
    if (bOpus_user !== null) {
        let user = JSON.parse(bOpus_user);
        return await STC.getCurrentUser().then((response) => {
            if (response.roles.length < 1 || response.roles.includes('disabled')) {
                STC.logout();
                return;
            }
            if (bOpus_user !== null) {
                user.metadata = response.metadata;
                user.token = response.token;
                user.role = response.roles;//user.metadata.role; //todo use user.role instead of user.metadata.role
                user.metadata.name = user.metadata.name !== undefined ? user.metadata.name: '';
                user.metadata.surname = user.metadata.surname !== undefined ? user.metadata.surname: '';
                localStorage.setItem("bOpus_user", JSON.stringify(user));
                if (user.metadata.exposedTreeNodes !== undefined) {
                    localStorage.setItem("exposedTreeNodes", JSON.stringify(user.metadata.exposedTreeNodes));
                }
                return user;
            }
        }).catch((error) => {
            console.log('ST error:', error);
            return user;
        });
    } else {
        return null;
    }
}

export default getUser;
